<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="6" md="6">
        <b-card>
          <div slot="header"><i class="icon-list"></i> Channel selection</div>
          <div v-if="loading">
            <div class="sk-circle-fade float-left">
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
              <div class="sk-grid-cube"></div>
            </div>
            <h4 class="float-left ml-4">Loading channels</h4>
          </div>
          <div v-else>
            <h4 class="smalltitle">Channel Selection</h4>
            By default, all events go to the default log channel. This can be
            quite spammy, especially in larger servers. To increase clarity
            without disabling events, you can redirect certain events to their
            respective channels. Each channel is associated with one card on
            this page.
            <hr />
            <b-form-group>
              <label for="log_channel_select" class="teenytinytitle"
                >Default log channel</label
              >
              <b-form-select
                id="log_channel_select"
                v-model="log_channel"
                value="Please select"
              >
                <option :value="{ name: 'No channel selected', id: null }"
                  >No channel selected</option
                >
                <option v-for="c in channels" :key="c.position" :value="c"
                  >#{{ c.name }}</option
                >
              </b-form-select>
            </b-form-group>
            <b-form-group>
              <label for="moderation_channel_select" class="teenytinytitle"
                >Member log channel</label
              >
              <b-form-select
                id="moderation_channel_select"
                v-model="moderation_channel"
                value="Please select"
              >
                <option :value="{ name: 'No channel selected', id: null }"
                  >No channel selected</option
                >
                <option v-for="c in channels" :key="c.position" :value="c"
                  >#{{ c.name }}</option
                >
              </b-form-select>
            </b-form-group>
            <b-form-group>
              <label for="server_channel_select" class="teenytinytitle"
                >Server log channel</label
              >
              <b-form-select
                id="server_channel_select"
                v-model="server_channel"
                value="Please select"
              >
                <option :value="{ name: 'No channel selected', id: null }"
                  >No channel selected</option
                >
                <option v-for="c in channels" :key="c.position" :value="c"
                  >#{{ c.name }}</option
                >
              </b-form-select>
            </b-form-group>
            <b-form-group>
              <label for="voice_channel_select" class="teenytinytitle"
                >Voice log channel</label
              >
              <b-form-select
                id="voice_channel_select"
                v-model="voice_channel"
                value="Please select"
              >
                <option :value="{ name: 'No channel selected', id: null }"
                  >No channel selected</option
                >
                <option v-for="c in channels" :key="c.position" :value="c"
                  >#{{ c.name }}</option
                >
              </b-form-select>
            </b-form-group>
            <b-form-group>
              <label for="message_channel_select" class="teenytinytitle"
                >Message log channel</label
              >
              <b-form-select
                id="message_channel_select"
                v-model="message_channel"
                value="Please select"
              >
                <option :value="{ name: 'No channel selected', id: null }"
                  >No channel selected</option
                >
                <option v-for="c in channels" :key="c.position" :value="c"
                  >#{{ c.name }}</option
                >
              </b-form-select>
            </b-form-group>
            <b-form-group>
              <label for="movement_channel_select" class="teenytinytitle"
                >Join/leave log channel</label
              >
              <b-form-select
                id="movement_channel_select"
                v-model="movement_channel"
                value="Please select"
              >
                <option :value="{ name: 'No channel selected', id: null }"
                  >No channel selected</option
                >
                <option v-for="c in channels" :key="c.position" :value="c"
                  >#{{ c.name }}</option
                >
              </b-form-select>
            </b-form-group>
            <b-form-group>
              <label for="movement_channel_select" class="teenytinytitle"
                >Ignored channels</label
              >
              <multiselect
                v-model="ignored_entities"
                :options="channels"
                :multiple="true"
                label="name"
                track-by="id"
              >
                <template slot="tag" slot-scope="{ option, search, remove }">
                  <span
                    class="multiselect__tag channelpicker__tag"
                    :style="{ borderColor: '#eee' }"
                  >
                    <i
                      aria-hidden="true"
                      tabindex="1"
                      @keydown.enter.prevent="remove(option)"
                      @mousedown.prevent="remove(option)"
                      class="multiselect__tag-icon channelpicker_icon"
                      :style="{ backgroundColor: '#eee' }"
                    ></i>
                    <font-awesome-icon
                      style="color:#999;margin-right:3px;"
                      icon="hashtag"
                    />
                    <span class="defaultcursor" v-text="option.name"></span>
                  </span>
                </template>
                <template slot="option" slot-scope="props">
                  <div class="option__desc">
                    <span class="option__title">
                      <font-awesome-icon style="color:#72767d" icon="hashtag" />
                      {{ props.option.name }}
                    </span>
                  </div>
                </template>
              </multiselect>
            </b-form-group>
          </div>
        </b-card>
        <b-card header="Message events" v-if="!loading">
          <b-list-group>
            <h4 class="smalltitle">Message Events</h4>
            <hr />

            <b-list-group-item for="checkbox1">
              <label for="checkbox1" style="margin:0">
                <b-form-checkbox id="checkbox1" v-model="statuses[0]"
                  >Deleted messages</b-form-checkbox
                >
              </label>
            </b-list-group-item>

            <b-list-group-item for="checkbox2">
              <label for="checkbox2" style="margin:0">
                <b-form-checkbox id="checkbox2" v-model="statuses[1]"
                  >Edited messages</b-form-checkbox
                >
              </label>
            </b-list-group-item>

            <b-list-group-item for="checkbox3">
              <label for="checkbox3" style="margin:0">
                <b-form-checkbox id="checkbox3" v-model="statuses[2]"
                  >Purged messages</b-form-checkbox
                >
              </label>
            </b-list-group-item>

          </b-list-group>
        </b-card>
        <b-card header="Member movement" v-if="!loading">
          <b-list-group>
            <h4 class="smalltitle">Members joining and leaving</h4>
            <hr />

            <b-list-group-item for="checkbox9">
              <label for="checkbox9" style="margin:0">
                <b-form-checkbox id="checkbox9" v-model="statuses[8]"
                  >Members joining</b-form-checkbox
                >
              </label>
            </b-list-group-item>

            <b-list-group-item for="checkbox10">
              <label for="checkbox10" style="margin:0">
                <b-form-checkbox id="checkbox10" v-model="statuses[9]"
                  >Members leaving</b-form-checkbox
                >
              </label>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
      <b-col sm="6" md="6">
        <b-card header="Server events" v-if="!loading">
          <b-list-group>
            <h4 class="smalltitle">Server Events</h4>
            These events relate to changes made to the server itself.
            <hr />

            <b-list-group-item for="checkbox11">
              <label for="checkbox11" style="margin:0">
                <b-form-checkbox id="checkbox11" v-model="statuses[10]"
                  >Channel creation</b-form-checkbox
                >
              </label>
            </b-list-group-item>

            <b-list-group-item for="checkbox12">
              <label for="checkbox12" style="margin:0">
                <b-form-checkbox id="checkbox12" v-model="statuses[11]"
                  >Updated channel</b-form-checkbox
                >
              </label>
            </b-list-group-item>
            <b-list-group-item for="checkbox13">
              <label for="checkbox13" style="margin:0">
                <b-form-checkbox id="checkbox13" v-model="statuses[12]"
                  >Channel deletion</b-form-checkbox
                >
              </label>
            </b-list-group-item>
            <b-list-group-item for="checkbox17">
              <label for="checkbox17" style="margin:0">
                <b-form-checkbox id="checkbox17" v-model="statuses[16]"
                  >Role creation</b-form-checkbox
                >
              </label>
            </b-list-group-item>
            <b-list-group-item for="checkbox18">
              <label for="checkbox18" style="margin:0">
                <b-form-checkbox id="checkbox18" v-model="statuses[17]"
                  >Role updates</b-form-checkbox
                >
              </label>
            </b-list-group-item>
            <b-list-group-item for="checkbox19">
              <label for="checkbox19" style="margin:0">
                <b-form-checkbox id="checkbox19" v-model="statuses[18]"
                  >Role deletion</b-form-checkbox
                >
              </label>
            </b-list-group-item>

            <b-list-group-item for="checkbox20">
              <label for="checkbox20" style="margin:0">
                <b-form-checkbox id="checkbox20" v-model="statuses[19]"
                  >Server updates</b-form-checkbox
                >
              </label>
            </b-list-group-item>

            <label for="checkbox21" style="margin:0">
              <b-list-group-item for="checkbox21">
                <b-form-checkbox id="checkbox21" v-model="statuses[20]"
                  >Emoji changes</b-form-checkbox
                >
              </b-list-group-item>
            </label>
          </b-list-group>
        </b-card>
        <b-card header="Member events" v-if="!loading">
          <b-list-group>
            <h4 class="smalltitle">Member Events</h4>
            <hr />

            <b-list-group-item for="checkbox4">
              <label for="checkbox4" style="margin:0">
                <b-form-checkbox id="checkbox4" v-model="statuses[3]"
                  >Role updates</b-form-checkbox
                >
              </label>
            </b-list-group-item>

            <b-list-group-item for="checkbox5">
              <label for="checkbox5" style="margin:0">
                <b-form-checkbox id="checkbox5" v-model="statuses[4]"
                  >Name changes</b-form-checkbox
                >
              </label>
            </b-list-group-item>

            <b-list-group-item for="checkbox6">
              <label for="checkbox6" style="margin:0">
                <b-form-checkbox id="checkbox6" v-model="statuses[5]"
                  >Avatar changes</b-form-checkbox
                >
              </label>
            </b-list-group-item>

            <b-list-group-item for="checkbox7">
              <label for="checkbox7" style="margin:0">
                <b-form-checkbox id="checkbox7" v-model="statuses[6]"
                  >Member bans</b-form-checkbox
                >
              </label>
            </b-list-group-item>

            <b-list-group-item for="checkbox8">
              <label for="checkbox8" style="margin:0">
                <b-form-checkbox id="checkbox8" v-model="statuses[7]"
                  >Member unbans</b-form-checkbox
                >
              </label>
            </b-list-group-item>
            <b-list-group-item for="checkbox23">
              <label for="checkbox23" style="margin:0">
                <b-form-checkbox id="checkbox23" v-model="statuses[22]"
                  >Member timeout</b-form-checkbox
                >
              </label>
            </b-list-group-item>
            <b-list-group-item for="checkbox24">
              <label for="checkbox24" style="margin:0">
                <b-form-checkbox id="checkbox24" v-model="statuses[23]"
                  >Member remove timeout</b-form-checkbox
                >
              </label>
            </b-list-group-item>
          </b-list-group>
        </b-card>
        <b-card header="Voice events" v-if="!loading">
          <b-list-group>
            <h4 class="smalltitle">Voice Events</h4>
            <hr />

            <b-list-group-item for="checkbox14">
              <label for="checkbox14" style="margin:0">
                <b-form-checkbox id="checkbox14" v-model="statuses[13]"
                  >Join voice channel</b-form-checkbox
                >
              </label>
            </b-list-group-item>

            <b-list-group-item for="checkbox15">
              <label for="checkbox15" style="margin:0">
                <b-form-checkbox id="checkbox15" v-model="statuses[14]"
                  >Move between voice channels</b-form-checkbox
                >
              </label>
            </b-list-group-item>

            <b-list-group-item for="checkbox16">
              <label for="checkbox16" style="margin:0">
                <b-form-checkbox id="checkbox16" v-model="statuses[15]"
                  >Leave voice channel</b-form-checkbox
                >
              </label>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>
    <div
      v-if="has_changed === true"
      class="popup col-8 offset-1 animated fadeIn"
    >
      <b-container
        fluid
        class="text-light text-center"
        style="padding-right:0px;"
      >
        <b-row class="mr-auto">
          <b-col cols="auto" class="mr-auto p-2">
            <h4 class="tinytitle" style="margin:7px 0px 0px 0px">
              Changes detected
            </h4>
          </b-col>
          <b-col cols="auto" class="p-2">
            <b-button variant="ghost-danger" v-on:click="reset_and_catchup"
              >Reset</b-button
            >

            <b-button variant="success" v-on:click="patch_and_catchup"
              >Confirm</b-button
            >
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div>
      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { Switch as cSwitch } from "@coreui/vue";
import { Callout } from "@coreui/vue";
import VueNotifications from "vue-notifications";
import miniToastr from "mini-toastr";
import Multiselect from "vue-multiselect";

const toastTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn"
};
miniToastr.init({ types: toastTypes });

function toast({ title, message, type, timeout, cb }) {
  return miniToastr[type](message, title, timeout, cb);
}

const toast_options = {
  success: toast,
  error: toast,
  info: toast,
  warn: toast
};

const reducer = (accumulator, currentValue, currentIndex) =>
  accumulator + (currentValue << currentIndex);

function finder(arr, id) {
  if (!id) {
    return { id: null, name: "No channel selected" };
  }
  var o = arr.find(obj => {
    return obj.id == id;
  });
  if (o && o.length !== 0) {
    return o;
  }
  return { id: null, name: "No channel selected" };
}

function listfinder(arr, id) {
  return arr.find(obj => {
    return obj.id === id;
  });
}

Vue.use(VueNotifications, toast_options);

export default {
  name: "Logging",
  components: {
    cSwitch,
    Multiselect
  },
  data: function() {
    return {
      labelIcon: {
        dataOn: "\u2713",
        dataOff: "\u2715"
      },
      statuses: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ],
      original_value: 0,
      channels: [],
      log_channel: {},
      moderation_channel: {},
      server_channel: {},
      voice_channel: {},
      message_channel: {},
      movement_channel: {},
      loading: true,
      cur_log_channel: {},
      cur_moderation_channel: {},
      cur_server_channel: {},
      cur_voice_channel: {},
      cur_message_channel: {},
      cur_movement_channel: {},
      ignored_entities: [],
      cur_ignored_entities: [],
      options: [],
      old_state_ignored_entities:  [],
    };
  },
  notifications: {
    showSuccessMsg: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Setting applied"
    },
    showInfoMsg: {
      type: VueNotifications.types.info,
      title: "No changes",
      message: "You already have that prefix."
    },
    showWarnMsg: {
      type: VueNotifications.types.warn,
      title: "Wow, man",
      message: "That's the kind of warning"
    },
    showSuccessPrefix: {
      type: VueNotifications.types.success,
      title: "Success!",
      message: "Prefix added"
    },
    showSuccessPrefixRemoval: {
      type: VueNotifications.types.warn,
      title: "Success!",
      message: "Prefix removed"
    },
    showErrorMsgPrefix: {
      type: VueNotifications.types.error,
      title: "Uh-oh",
      message: "You need to enter a prefix!"
    },
    showErrorMsgNick: {
      type: VueNotifications.types.warn,
      title: "Uh-oh",
      message: "You need to enter a nickname!"
    },
    showErrorMsgDuplicate: {
      type: VueNotifications.types.warn,
      title: "Uh-oh",
      message: "You already have that prefix!"
    },
    showErrorMsgTooManyPrefixes: {
      type: VueNotifications.types.error,
      title: "Uh-oh",
      message: "You already have the maximum allowed number of prefixes!"
    }
  },
  methods: {
    current_catchup() {
      this.cur_log_channel = this.log_channel;
      this.cur_moderation_channel = this.moderation_channel;
      this.cur_server_channel = this.server_channel;
      this.cur_voice_channel = this.voice_channel;
      this.cur_message_channel = this.message_channel;
      this.cur_movement_channel = this.movement_channel;
      this.cur_ignored_entities = this.ignored_entities;
      this.old_state_ignored_entities = this.ignored_entities;
      this.original_value = this.statuses.reduce(reducer);
      this.$forceUpdate();
    },
    reset_settings() {
      this.bit_to_array(this.original_value);
      (this.log_channel = this.cur_log_channel),
        (this.moderation_channel = this.cur_moderation_channel),
        (this.server_channel = this.cur_server_channel),
        (this.voice_channel = this.cur_voice_channel),
        (this.message_channel = this.cur_message_channel),
        (this.movement_channel = this.cur_movement_channel),
        (this.ignored_entities = this.cur_ignored_entities); //.map(x => listfinder(this.channels, x))
    },
    patch_settings() {
      this.axios
        .put("/api/v1/servers/" + this.$route.params.guild_id + "/logging", {
          moderation_channel:
            this.moderation_channel == null ? null : this.moderation_channel.id,
          server_channel:
            this.server_channel == null ? null : this.server_channel.id,
          voice_channel:
            this.voice_channel == null ? null : this.voice_channel.id,
          message_channel:
            this.message_channel == null ? null : this.message_channel.id,
          movement_channel:
            this.movement_channel == null ? null : this.movement_channel.id,

          ignored_entities:
            this.ignored_entities == []
              ? []
              : this.ignored_entities.map(x => x.id),
          logging: this.logging_val,
          log_channel: this.log_channel == null ? null : this.log_channel.id,
          old_state_ignored_entities: this.old_state_ignored_entities==[]?[]:this.old_state_ignored_entities.map(x => x.id),
        })
        .then(this.showSuccessMsg());
    },
    patch_and_catchup() {
      this.patch_settings();
      this.current_catchup();
    },
    reset_and_catchup() {
      this.reset_settings();
      this.current_catchup();
    },
    bit_to_array(bitmask) {
      for (let [i, e] of this.statuses.entries()) {
        this.statuses[i] = (bitmask & Math.pow(2, i)) !== 0;
      }
    }
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/logging")
      .then(r => {
        this.loading = false;
        this.bit_to_array(r.data.logging);
        this.channels = r.data.channels;

        (this.log_channel = finder(this.channels, r.data.log_channel)),
          (this.moderation_channel = finder(
            this.channels,
            r.data.moderation_channel
          )),
          (this.server_channel = finder(this.channels, r.data.server_channel)),
          (this.voice_channel = finder(this.channels, r.data.voice_channel)),
          (this.message_channel = finder(
            this.channels,
            r.data.message_channel
          )),
          (this.movement_channel = finder(
            this.channels,
            r.data.movement_channel
          )),
          (this.ignored_entities =
            r.data.ignored_entities == null
              ? []
              : this.channels.filter(x =>
                  r.data.ignored_entities.includes(x.id)
                ));

        this.cur_log_channel = this.log_channel;
        this.cur_moderation_channel = this.moderation_channel;
        this.cur_server_channel = this.server_channel;
        this.cur_voice_channel = this.voice_channel;
        this.cur_message_channel = this.message_channel;
        this.cur_movement_channel = this.movement_channel;
        this.cur_ignored_entities = this.ignored_entities;
        this.old_state_ignored_entities = this.ignored_entities;
        this.original_value = r.data.logging;
        this.$forceUpdate();
      });
  },
  created: function() {
    this.$Progress.start();
    this.axios
      .get("/api/v1/servers/" + this.$route.params.guild_id + "/logging")
      .then(r => {
        this.loading = false;
        setTimeout(() => {
          this.bit_to_array(r.data.logging);
          this.original_value = r.data.logging;
        }, 0); // Wow I sure love javascript

        this.channels = r.data.channels;

        (this.log_channel = finder(this.channels, r.data.log_channel)),
          (this.moderation_channel = finder(
            this.channels,
            r.data.moderation_channel
          )),
          (this.server_channel = finder(this.channels, r.data.server_channel)),
          (this.voice_channel = finder(this.channels, r.data.voice_channel)),
          (this.message_channel = finder(
            this.channels,
            r.data.message_channel
          )),
          (this.movement_channel = finder(
            this.channels,
            r.data.movement_channel
          )),
          (this.ignored_entities =
            r.data.ignored_entities == null
              ? []
              : this.channels.filter(x =>
                  r.data.ignored_entities.includes(x.id)
                ));

        this.cur_log_channel = this.log_channel;
        this.cur_moderation_channel = this.moderation_channel;
        this.cur_server_channel = this.server_channel;
        this.cur_voice_channel = this.voice_channel;
        this.cur_message_channel = this.message_channel;
        this.cur_movement_channel = this.movement_channel;
        this.cur_ignored_entities = this.ignored_entities;
        this.old_state_ignored_entities = this.ignored_entities;

        this.$Progress.finish();
        //this.$forceUpdate();
      });
  },
  computed: {
    logging_val: function() {
      return this.statuses.reduce(reducer);
    },
    has_changed: function() {
      if (this.logging_val !== this.original_value) {
        return true;
      }
      if (this.log_channel.id !== this.cur_log_channel.id) {
        return true;
      }
      if (this.moderation_channel.id !== this.cur_moderation_channel.id) {
        return true;
      }
      if (this.server_channel.id !== this.cur_server_channel.id) {
        return true;
      }
      if (this.voice_channel.id !== this.cur_voice_channel.id) {
        return true;
      }
      if (this.message_channel.id !== this.cur_message_channel.id) {
        return true;
      }
      if (this.movement_channel.id !== this.cur_movement_channel.id) {
        return true;
      }
      if (
        JSON.stringify(this.ignored_entities) !==
        JSON.stringify(this.cur_ignored_entities)
      ) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style>
/* IE fix */
#card-chart-01,
#card-chart-02 {
  width: 100% !important;
}

.confirm {
  align-items: center;
  position: fixed;
  bottom: 20px;
}
</style>
<style lang="scss" scoped>
$grey: rgb(34, 37, 39);
.popup {
  background-color: $grey;
  border-radius: 0.5rem;
  display: flex;
  //margin: 0.5rem;
  position: fixed;
  bottom: 20px;
  z-index: 2000;
  //padding: 0.65rem;
  align-items: center;
  box-shadow: 0.05rem 0.1rem 0.2rem 0.2rem rgba(0, 0, 0, 0.08);
  transition: bottom 600ms cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
</style>
